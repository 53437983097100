<template>
  <div class="bg">
    <div class="left-content">
      <span style=" font-size: 26px; color: white">喜来缘采购信息管理系统</span>
      <img :src="log_pic" width="90%" height="80%">
    </div>
    <a-form
        :form="form"
        class="login-form"
        @submit="handleSubmit">
      <div v-if="!forgetPwd" class="right-form-content">
        <a-form-item class="login-custom-header">
          <div>
            <span>欢迎登录</span>
            <span></span>
          </div>
        </a-form-item>
        <div class="login-switch">
          <a-form-item>
            <div :class="loginIndex===1?'activation':''"
                 style="margin-right: 100px"
                 @click="loginIndex = 1;loginSwitch=true">账号密码登录
            </div>
          </a-form-item>
          <a-form-item>
            <div :class="loginIndex===2?'activation':''"
                 @click="loginIndex = 2;loginSwitch=false">手机号登录
            </div>
          </a-form-item>
        </div>
        <!--密码登录-->
        <div v-if="loginSwitch">
          <a-form-item>
            <a-input
                oninput="if(value.length>11)value = value.slice(0,11)"
                v-decorator="['phone',rules.phone]"
                type="text"
                key="0"
                placeholder="手机号"
                allowClear>
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input-password
                v-decorator="['password',rules.password]"
                type="password"
                placeholder="密码"
                allowClear
            >
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
            </a-input-password>
          </a-form-item>
        </div>
        <!--手机号登录-->
        <div v-else>
          <a-form-item>
            <a-input
                oninput="if(value.length>11)value = value.slice(0,11)"
                v-decorator="['phone',rules.phone]"
                type="text"
                key="1"
                placeholder="手机号"
                allowClear
            >
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="smsCode">
              <a-input
                  v-decorator="['smsCode',rules.smsCode]"
                  type="text"
                  style="width: 205px"
                  placeholder="验证码"
                  allowClear
              >
                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
              </a-input>
              <a-button class="getCode" :loding="this.loading" :disabled="this.disabled" icon="poweroff"
                        @click="getCode" type="primary" style="margin-left: 30px;">
                {{ loading === true ? this.delayCount + "秒后重发" : "获取验证码" }}
              </a-button>
            </div>
          </a-form-item>
        </div>
        <a-form-item>
          <div style="padding-top: 18px">
            <a-select default-value="0"
                      :getPopupContainer="trigger => trigger.parentNode"
                      style="width: 120px"
                      @change="handleChange"
            >
              <a-select-option value=0>
                商户/餐厅
              </a-select-option>
              <a-select-option value=1>
                供应商
              </a-select-option>
              <a-select-option value=2>
                超级管理员
              </a-select-option>
            </a-select>
            <a class="login-form-forgot" @click="changePwdState">
              忘记密码
            </a>
          </div>
          <a-button type="primary" html-type="submit" class="login-form-button" :loading="loginLoading">
            登录
          </a-button>
          <a @click="goToRegister">
            公司注册
          </a>
        </a-form-item>
      </div>
      <div v-else class="right-form-content">
        <a-form :form="forgetForm"
                class="forget-form"
                @submit="forgetHandleSubmit">
          <a-form-item class="login-custom-header" style="font-size: 20px">
            <div>
              <span>重置密码</span>
              <span></span>
            </div>
          </a-form-item>
          <a-form-item>
            <a-input
                oninput="if(value.length>11)value = value.slice(0,11)"
                v-decorator="['phone',rules.phone]"
                type="text"
                key="1"
                placeholder="手机号"
                allowClear
            >
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="smsCode">
              <a-input
                  v-decorator="['smsCode',rules.smsCode]"
                  type="text"
                  style="width: 205px"
                  placeholder="验证码"
                  allowClear
              >
                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
              </a-input>
              <a-button class="getCode" :loding="this.loading" :disabled="this.disabled" icon="poweroff"
                        @click="getCode" type="primary" style="margin-left: 30px;">
                {{ loading === true ? this.delayCount + "秒后重发" : "获取验证码" }}
              </a-button>
            </div>
          </a-form-item>
          <a-form-item>
            <a-input-password
                v-decorator="['password',rules.password]"
                key="0"
                type="password"
                placeholder="新密码"
                allowClear>
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input-password
                v-decorator="['repeatPwd',rules.password]"
                key="1"
                type="password"
                placeholder="重复密码"
                allowClear
            >
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <div class="change-pwd-footer">
              <a-select default-value="0"
                        :getPopupContainer="trigger => trigger.parentNode"
                        style="width: 120px"
                        @change="handleChange">
                <a-select-option value=0>
                  商户/餐厅
                </a-select-option>
                <a-select-option value=1>
                  供应商
                </a-select-option>
                <a-select-option value=2>
                  超级管理员
                </a-select-option>
              </a-select>

              <a-button type="primary" html-type="submit" class="chang-pwd-button">
                确定
              </a-button>
              <a-button type="primary" html-type="button" @click="changePwdState" class="chang-pwd-button">
                返回
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-form>
    <Vcode :show="isShow"
           @success="success"
           @close="close"/>
  </div>
</template>

<script>
import Vcode from 'vue-puzzle-vcode'
import {isMobile} from '@/utils/common'



export default {
  name: "login",
  components: {
    Vcode
  },
  data() {
    return {
      log_pic:require('../assets/images/login/login_pic.png'),
      form: this.$form.createForm(this, {name: 'login_form'}),
      forgetForm: this.$form.createForm(this, {name: 'forget_form'}),
      rules: {
        phone: {
          rules: [
            {required: true, message: '请输入手机号!'},
          ]
        },
        password: {
          rules: [
            {required: true, message: '请输入密码!'},
          ]
        },
        smsCode: {
          rules: [
            {required: true, message: '请输入验证码!'},
          ]
        }
      },
      userData: {
        mode: '',
        phone: '',
        password: '',
        smsCode: '',
        userType: 0,
      },
      userForgetData: {
        phone: '',
        code: '',
        password: '',
        repeatPwd: '',
        userType: 0,
      },
      isShow: false,
      required: true,
      loading: false,
      loginLoading:false,
      delayCount: 60,
      disabled: false,
      loginIndex: 1,
      loginSwitch: true,
      forgetPwd: false,
      redirect:'/',
    }
  },
  methods: {
    //====滑块验证相关======
    success() {
      this.isShow = false;
      this.submitForm(this.userData);
    },
    close() {
      this.isShow = false;
    },
    //=======END=========
    // 跳转
    goToRegister () {
      this.$router.push("/register");
    },
    //登录
    submitForm(userData) {
      this.loginLoading = true;
      if (this.loginSwitch) {
        userData.mode = 0 //密码登录
      } else {
        userData.mode = 1 //验证码登录
      }
      this.$api.common.login(userData).then(res => {
        if(res.code===200){
          this.$message.success(res.msg);
          this.loginSuccess(res.data);
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
      this.loginLoading = false;
    },
    loginSuccess(data){
      this.$store.commit('setToken',data.token);
      this.$store.commit('setUserInfo',data);
      const roleIds = data.roleIds;
      if(data.companyStatus==0){
        this.redirect = '/account/personal/info'
      }
      if(data.companyStatus==1){
        if(roleIds.indexOf('2')!==-1||roleIds.indexOf('6')!==-1){
          this.redirect = '/datacenter';
        }
      }
      this.$router.replace(this.redirect);
    },
    //表单提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.userData.phone = values.phone;
          this.userData.password = values.password;
          if (!isMobile(this.userData.phone)) {
            this.$message.error('请输入正确的手机号码')
            return
          }
          if (values.smsCode) {
            this.userData.smsCode = values.smsCode;
          }
          //调用滑块验证方法success
          this.isShow = true;
        }
      });
    },
    forgetHandleSubmit(e) {
      e.preventDefault();
      this.forgetForm.validateFields((err, values) => {
        if (!err) {
          if (values.password !== values.repeatPwd) {
            this.$message.error('两次输入的密码不一致')
            return
          }
          this.userForgetData.phone = values.phone;
          this.userForgetData.password = values.password;
          this.userForgetData.repeatPwd = values.repeatPwd;
          if (!isMobile(this.userForgetData.phone)) {
            this.$message.error('请输入正确的手机号码')
            return
          }
          if (values.smsCode) {
            this.userForgetData.code = values.smsCode;
          }
          this.$api.common.changePwd(this.userForgetData).then(res=>{
            if (res.code === 200) {
              this.$message.success(res.msg);
              setTimeout(()=>{
                this.changePwdState();
              },1000);
            }
          });
        }
      });
    },
    handleChange(value) {
      if (this.forgetPwd) {
        this.userForgetData.userType = value
      } else {
        this.userData.userType = value;
      }
    },
    changePwdState() {
      this.forgetPwd = !this.forgetPwd;
    },
    smsLoading() {
      this.doLoading();
    },
    doLoading() {
      let siv = setInterval(() => {
        this.delayCount--;
        if (this.delayCount < 1) {
          this.loading = false;
          this.disabled = false;
          this.delayCount = 5;
          clearInterval(siv)
        } else {
          this.loading = true;
          this.disabled = true;
        }
      }, 1000)
    },
    getCode() {
      if (this.forgetPwd) {
        const params = {
          phone:this.forgetForm.getFieldValue('phone'),
          userType: this.userForgetData.userType,
        }
        if (!isMobile(params.phone)) {
          this.$message.error('请输入正确的手机号码')
          return
        }
        this.$api.common.sendCode(params).then(res=>{
          if(res.code===200||res.success){
            this.$message.success(res.msg);
          }
        })
        //等待重发验证码
        this.smsLoading();
      } else {
        const params = {
          phone: this.form.getFieldValue('phone'),
          userType: this.userData.userType,
        }
        if (!isMobile(params.phone)) {
          this.$message.error('请输入正确的手机号码')
          return
        }
        this.$api.common.sendCode(params).then(res=>{
          if(res.code===200||res.success){
            this.$message.success(res.msg);
          }
        });
        //等待重发验证码
        this.smsLoading();
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.bg {
  height: 100vh;
  background: linear-gradient(to bottom, #31324c, #FFFFFF);
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  font-family: 楷体;

  .left-content {
    width: 400px;
    height: 444px;
    padding-top: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #3a62d7;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    text-align: center;
    opacity: 0.9;
  }

  .login-form {
    border-radius: 10px;
    background: #f7f7f7;
    max-width: 800px;
    max-height: 888px;
    display: flex;
  }
  .right-form-content {

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #f7f7f7;
    padding: 20px 30px;
    width: 400px;
    height: 444px;

    .login-custom-header {
      padding: 0px;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      font-family: 楷体;
    }

    .login-switch {
      display: flex;
      justify-content: center;

      .div {
        line-height: 30px;
        border-bottom: 2px solid #1890ff;
        cursor: pointer;
      }

      .activation {
        border-bottom: 2px solid #1890ff;
        color: #1890ff;
      }
    }

    .smsCode {
      display: flex;
      padding-top: 5px;
      padding-bottom: 3px;
    }

    .login-form-forgot {
      float: right;
    }

    .login-form-button {
      justify-content: center;
      width: 100%;
    }

    .change-pwd-footer {
      display: flex;
      justify-content: center;
    }

    .chang-pwd-button {
      justify-content: center;
      width: 38%;
      margin: 0px 15px;
    }
  }

}

</style>